<template>
  <div class="card gutter-b card-stretch">
    <!-- <div class="card-header h-auto border-0">
      <div class="card-title">
        <h3 class="card-label">
          <span class="d-block text-dark font-weight-bolder"
            >Weblog</span
          >
          <span class="d-block text-muted mt-2 font-size-sm"
            >More than 500+ new orders</span
          >
        </h3>
      </div>
    </div> -->
    <!-- <div class="card-body d-flex flex-column p-0"> -->
      
    <div class="table-responsive">
      <!-- Start of loading animation -->
      <div class="p-10" v-if="loading">
      <!-- <div class="card-body"> -->
      <div class="spinner spinner-primary spinner-md spinner-center"></div>
      <p class="text-6xl font-bold text-center text-gray-500 animate-pulse mt-5">
        Loading...
      </p>
      <!-- </div> -->
      <!-- End of loading animation -->
      <!-- Start of error alert -->
      <v-alert border="top" color="red lighten-2" v-if="error.length > 0" dark>
        {{ error.response }}
      </v-alert>
      </div>

<vue-good-table  v-if="!loading && companyId == 2"
  :columns="headerColumns"
  :rows="RegionData"
  styleClass="vgt-table /*striped*/"
  :fixed-header="true"
  max-height="800px"
  compactMode
  :pagination-options="{
    enabled: false,
    perPage: 50,
    mode: 'pages'
  }"  
  :search-options="{
    enabled: false,
    placeholder: 'Search for a customer',
  }">
  <template slot="table-row" slot-scope="props">
    <span v-if="props.column.field == 'totalY'">
      <span v-if="props.row.totalY > props.row.totalY1" class="text-success font-weight-bold"> {{ formatNumbers(props.row.totalY) }} ({{ formatNumbers((props.row.totalY - props.row.totalY1) / props.row.totalY1 * 100) }}%)</span>
      <span v-if="props.row.totalY == '' && props.row.totalY1 == ''" class="svg-icon">-</span>
      <span v-if="props.row.totalY < props.row.totalY1 && props.row.totalY != ''" class="text-danger font-weight-bold">{{ formatNumbers(props.row.totalY) }} ({{ formatNumbers((props.row.totalY - props.row.totalY1) / props.row.totalY1 * 100) }}%)</span>
      <span v-if="props.row.totalY < props.row.totalY1 && props.row.totalY == ''" class="text-danger font-weight-bold">{{ formatNumbers(0) }} (-100%)</span>
    </span>
    <span v-else-if="props.column.field == 'totalDiff'">
      <span v-if="props.row.totalDiff > 0 && props.row.totalDiff != ''" class="text-success font-weight-bold"> {{ formatNumbers(props.row.totalDiff) }}</span>
      <span v-if="props.row.totalDiff == ''" class="svg-icon">-</span>
      <!-- <span v-if="props.row.totalDiff == ''" class="svg-icon"><i class="flaticon2-up text-success"></i>-</span> -->
      <span v-if="props.row.totalDiff < 0" class="text-danger font-weight-bold">{{ formatNumbers(props.row.totalDiff) }}</span>
    </span>
    <span v-else-if="props.column.field == 'yw'">
      <span v-if="props.row.yw > props.row.lyw" class="text-success font-weight-bold"> {{ formatNumbers(props.row.yw) }} ({{ formatNumbers((props.row.yw - props.row.lyw) / props.row.lyw * 100) }}%)</span>
      <span v-if="props.row.yw == '' && props.row.lyw == ''" class="svg-icon">-</span>
      <span v-if="props.row.yw < props.row.lyw && props.row.yw != ''" class="text-danger font-weight-bold">{{ formatNumbers(props.row.yw) }} ({{ formatNumbers((props.row.yw - props.row.lyw) / props.row.lyw * 100) }}%)</span>
      <span v-if="props.row.yw < props.row.lyw && props.row.yw == ''" class="text-danger font-weight-bold">{{ formatNumbers(props.row.yw - props.row.lyw) }} (-100%)</span>
    </span>
    <span v-else-if="props.column.field == 'yW1'">
      <span v-if="props.row.yW1 > props.row.lyW1" class="text-success font-weight-bold"> {{ formatNumbers(props.row.yW1) }} ({{ formatNumbers((props.row.yW1 - props.row.lyW1) / props.row.lyW1 * 100) }}%)</span>
      <span v-if="props.row.yW1 == '' && props.row.lyW1 == ''" class="svg-icon">-</span>
      <span v-if="props.row.yW1 < props.row.lyW1 && props.row.yW1 != ''" class="text-danger font-weight-bold">{{ formatNumbers(props.row.yW1) }} ({{ formatNumbers((props.row.yW1 - props.row.lyW1) / props.row.lyW1 * 100) }}%)</span>
      <span v-if="props.row.yW1 < props.row.lyW1 && props.row.yW1 == ''" class="text-danger font-weight-bold">{{ formatNumbers(props.row.yW1 - props.row.lyW1) }} (-100%)</span>
    </span>
    <span v-else-if="props.column.field == 'yW2'">
      <span v-if="props.row.yW2 > props.row.lyW2" class="text-success font-weight-bold"> {{ formatNumbers(props.row.yW2) }} ({{ formatNumbers((props.row.yW2 - props.row.lyW2) / props.row.lyW2 * 100) }}%)</span>
      <span v-if="props.row.yW2 == '' && props.row.lyW2 == ''" class="svg-icon">-</span>
      <span v-if="props.row.yW2 < props.row.lyW2 && props.row.yW2 != ''" class="text-danger font-weight-bold">{{ formatNumbers(props.row.yW2) }} ({{ formatNumbers((props.row.yW2 - props.row.lyW2) / props.row.lyW2 * 100) }}%)</span>
      <span v-if="props.row.yW2 < props.row.lyW2 && props.row.yW2 == ''" class="text-danger font-weight-bold">{{ formatNumbers(props.row.yW2 - props.row.lyW2) }} (-100%)</span>
    </span>
    <span v-else-if="props.column.field == 'yW3'">
      <span v-if="props.row.yW3 > props.row.lyW3" class="text-success font-weight-bold"> {{ formatNumbers(props.row.yW3) }} ({{ formatNumbers((props.row.yW3 - props.row.lyW3) / props.row.lyW3 * 100) }}%)</span>
      <span v-if="props.row.yW3 == '' && props.row.lyW3 == ''" class="svg-icon">-</span>
      <span v-if="props.row.yW3 < props.row.lyW3 && props.row.yW3 != ''" class="text-danger font-weight-bold">{{ formatNumbers(props.row.yW3) }} ({{ formatNumbers((props.row.yW3 - props.row.lyW3) / props.row.lyW3 * 100) }}%)</span>
      <span v-if="props.row.yW3 < props.row.lyW3 && props.row.yW3 == ''" class="text-danger font-weight-bold">{{ formatNumbers(props.row.yW3 - props.row.lyW3) }} (-100%)</span>
    </span>
    <span v-else>
      {{props.formattedRow[props.column.field]}}
    </span>
  </template>
</vue-good-table>
<div class="p-5"></div>
      <vue-good-table  v-if="!loading"
        :columns="columns"
        :rows="data"
        styleClass="vgt-table /*striped*/"
        :fixed-header="true"
        max-height="800px"
        compactMode
        :pagination-options="{
          enabled: false,
          perPage: 50,
          mode: 'pages'
        }"  
        :search-options="{
          enabled: false,
          placeholder: 'Search for a customer',
        }">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'totalY'">
            <span v-if="props.row.totalY > props.row.totalY1" class="text-success font-weight-bold"> {{ formatNumbers(props.row.totalY) }} ({{ formatNumbers((props.row.totalY - props.row.totalY1) / props.row.totalY1 * 100) }}%)</span>
            <span v-if="props.row.totalY == '' && props.row.totalY1 == ''" class="svg-icon">-</span>
            <span v-if="props.row.totalY < props.row.totalY1 && props.row.totalY != ''" class="text-danger font-weight-bold">{{ formatNumbers(props.row.totalY) }} ({{ formatNumbers((props.row.totalY - props.row.totalY1) / props.row.totalY1 * 100) }}%)</span>
            <span v-if="props.row.totalY < props.row.totalY1 && props.row.totalY == ''" class="text-danger font-weight-bold">{{ formatNumbers(0) }} (-100%)</span>
          </span>
          <span v-else-if="props.column.field == 'totalDiff'">
            <span v-if="props.row.totalDiff > 0 && props.row.totalDiff != ''" class="text-success font-weight-bold"> {{ formatNumbers(props.row.totalDiff) }}</span>
            <span v-if="props.row.totalDiff == ''" class="svg-icon">-</span>
            <!-- <span v-if="props.row.totalDiff == ''" class="svg-icon"><i class="flaticon2-up text-success"></i>-</span> -->
            <span v-if="props.row.totalDiff < 0" class="text-danger font-weight-bold">{{ formatNumbers(props.row.totalDiff) }}</span>
          </span>
          <span v-else-if="props.column.field == 'yw'">
            <span v-if="props.row.yw > props.row.lyw" class="text-success font-weight-bold"> {{ formatNumbers(props.row.yw) }} ({{ formatNumbers((props.row.yw - props.row.lyw) / props.row.lyw * 100) }}%)</span>
            <span v-if="props.row.yw == '' && props.row.lyw == ''" class="svg-icon">-</span>
            <span v-if="props.row.yw < props.row.lyw && props.row.yw != ''" class="text-danger font-weight-bold">{{ formatNumbers(props.row.yw) }} ({{ formatNumbers((props.row.yw - props.row.lyw) / props.row.lyw * 100) }}%)</span>
            <span v-if="props.row.yw < props.row.lyw && props.row.yw == ''" class="text-danger font-weight-bold">{{ formatNumbers(props.row.yw - props.row.lyw) }} (-100%)</span>
          </span>
          <span v-else-if="props.column.field == 'yW1'">
            <span v-if="props.row.yW1 > props.row.lyW1" class="text-success font-weight-bold"> {{ formatNumbers(props.row.yW1) }} ({{ formatNumbers((props.row.yW1 - props.row.lyW1) / props.row.lyW1 * 100) }}%)</span>
            <span v-if="props.row.yW1 == '' && props.row.lyW1 == ''" class="svg-icon">-</span>
            <span v-if="props.row.yW1 < props.row.lyW1 && props.row.yW1 != ''" class="text-danger font-weight-bold">{{ formatNumbers(props.row.yW1) }} ({{ formatNumbers((props.row.yW1 - props.row.lyW1) / props.row.lyW1 * 100) }}%)</span>
            <span v-if="props.row.yW1 < props.row.lyW1 && props.row.yW1 == ''" class="text-danger font-weight-bold">{{ formatNumbers(props.row.yW1 - props.row.lyW1) }} (-100%)</span>
          </span>
          <span v-else-if="props.column.field == 'yW2'">
            <span v-if="props.row.yW2 > props.row.lyW2" class="text-success font-weight-bold"> {{ formatNumbers(props.row.yW2) }} ({{ formatNumbers((props.row.yW2 - props.row.lyW2) / props.row.lyW2 * 100) }}%)</span>
            <span v-if="props.row.yW2 == '' && props.row.lyW2 == ''" class="svg-icon">-</span>
            <span v-if="props.row.yW2 < props.row.lyW2 && props.row.yW2 != ''" class="text-danger font-weight-bold">{{ formatNumbers(props.row.yW2) }} ({{ formatNumbers((props.row.yW2 - props.row.lyW2) / props.row.lyW2 * 100) }}%)</span>
            <span v-if="props.row.yW2 < props.row.lyW2 && props.row.yW2 == ''" class="text-danger font-weight-bold">{{ formatNumbers(props.row.yW2 - props.row.lyW2) }} (-100%)</span>
          </span>
          <span v-else-if="props.column.field == 'yW3'">
            <span v-if="props.row.yW3 > props.row.lyW3" class="text-success font-weight-bold"> {{ formatNumbers(props.row.yW3) }} ({{ formatNumbers((props.row.yW3 - props.row.lyW3) / props.row.lyW3 * 100) }}%)</span>
            <span v-if="props.row.yW3 == '' && props.row.lyW3 == ''" class="svg-icon">-</span>
            <span v-if="props.row.yW3 < props.row.lyW3 && props.row.yW3 != ''" class="text-danger font-weight-bold">{{ formatNumbers(props.row.yW3) }} ({{ formatNumbers((props.row.yW3 - props.row.lyW3) / props.row.lyW3 * 100) }}%)</span>
            <span v-if="props.row.yW3 < props.row.lyW3 && props.row.yW3 == ''" class="text-danger font-weight-bold">{{ formatNumbers(props.row.yW3 - props.row.lyW3) }} (-100%)</span>
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import JwtService from "@/core/services/jwt.service";
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
// import _ from 'lodash';


export default {
  // name: "line-base",
  components: {
    // MixedWidget1,
    VueGoodTable,
  },  
  data() {
    return {
      companyId: JwtService.authorizeToken().companyId,
      loading: true,
      error: [],
      data: [],
      RegionData: [],
      headerColumns: [
        {
          label: 'Zipcode',
          field: 'zipcode',
          // type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-hover-primary font-size-lg',
          width: '100px',
          // filterOptions: {
          //   enabled: true, // enable filter for this column
          //   placeholder: 'Filter >', // placeholder for filter input
          //   filterFn: this.columnFilterFn, //custom filter function that
          //   filterDropdownItems: [
          //     { value: '2000', text: '2000+' },
          //     { value: '3000', text: '3000+' },
          //     { value: '4000', text: '4000+' },
          //     { value: '5000', text: '5000+' },
          //     { value: '6000', text: '6000+' },
          //     { value: '7000', text: '7000+' },
          //     { value: '8000', text: '8000+' },
          //     { value: '9000', text: '9000+' }
          //   ],
          // },
        },
        // {
        //   label: 'Last Invoice',
        //   field: 'lastInvoiceDate',
        //   sortable: true,
        //   thClass: 'text-muted font-size-sm',
        //   tdClass: 'text-muted font-size-sm',
        //   width: '110px',
        //   filterOptions: {
        //     enabled: true, // enable filter for this column
        //   },
        // },
        {
          label: 'totalY',
          field: 'totalY',
          type: 'number',
          sortable: true,
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '120px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            placeholder: 'totalY', // placeholder for filter input
          },
        },
        {
          label: 'totalY1',
          field: 'totalY1',
          type: 'number',
          sortable: true,
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '100px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            placeholder: 'totalY1', // placeholder for filter input
          },
        },
        {
          label: 'totalDiff',
          field: 'totalDiff',
          type: 'number',
          sortable: true,
          //sortFn: this.sortFn,
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '100px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            // placeholder: 'totalDiff', // placeholder for filter input
          },
        },
        {
          label: 'Week-1',
          field: 'yw',
          type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '120px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            placeholder: 'yw', // placeholder for filter input
          },
        },
        {
          label: 'Week-2',
          field: 'yW1',
          type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '120px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            placeholder: 'yW1', // placeholder for filter input
          },
        },
        {
          label: 'Week-3',
          field: 'yW2',
          type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '120px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            placeholder: 'yW2', // placeholder for filter input
          },
        },
        {
          label: 'Week-4',
          field: 'yW3',
          type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '120px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            placeholder: 'yW3', // placeholder for filter input
          },
        },
        {
          label: 'Week-1 LY',
          field: 'lyw',
          type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '120px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            placeholder: 'lyw', // placeholder for filter input
          },
        },
        {
          label: 'Week-2 LY',
          field: 'lyW1',
          type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '120px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            placeholder: 'lyW1', // placeholder for filter input
          },
        },
        {
          label: 'Week-3 LY',
          field: 'lyW2',
          type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '120px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            placeholder: 'lyW2', // placeholder for filter input
          },
        },
        {
          label: 'Week-4 LY',
          field: 'lyW3',
          type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '120px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            placeholder: 'lyW3', // placeholder for filter input
          },
        },
        {
          label: 'Zipcode',
          field: 'zipcode',
          // type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-hover-primary font-size-lg',
          width: '100px',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: 'Filter >', // placeholder for filter input
            filterFn: this.columnFilterFn, //custom filter function that
            filterDropdownItems: [
              { value: '2000', text: '2000+' },
              { value: '3000', text: '3000+' },
              { value: '4000', text: '4000+' },
              { value: '5000', text: '5000+' },
              { value: '6000', text: '6000+' },
              { value: '7000', text: '7000+' },
              { value: '8000', text: '8000+' },
              { value: '9000', text: '9000+' }
            ],
          },
        },
      ],
      // lineId: this.$route.params.id,
      columns: [
        {
          label: 'Debnr',
          field: 'debnr',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-hover-primary font-size-lg',
          width: '100px',
          filterOptions: {
          // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter', // placeholder for filter input
            // filterValue: 'Jane', // initial populated value for this filter
            // filterDropdownItems: [], // dropdown (with selected values) instead of text input
            // filterFn: this.columnFilterFn, //custom filter function that
            // trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        // {
        //   label: 'Name',
        //   field: 'debnaam',
        //   thClass: 'text-muted font-size-sm',
        //   tdClass: 'text-dark-75 font-weight-bolder text-hover-primary font-size-lg',
        //   filterOptions: {
        //     enabled: true, // enable filter for this column
        //   },
        // },
        // {
        //   label: 'Last Invoice',
        //   field: 'lastInvoiceDate',
        //   sortable: true,
        //   thClass: 'text-muted font-size-sm',
        //   tdClass: 'text-muted font-size-sm',
        //   width: '110px',
        //   filterOptions: {
        //     enabled: true, // enable filter for this column
        //   },
        // },
        {
          label: 'totalY',
          field: 'totalY',
          type: 'number',
          sortable: true,
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '120px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            placeholder: 'totalY', // placeholder for filter input
          },
        },
        {
          label: 'totalY1',
          field: 'totalY1',
          type: 'number',
          sortable: true,
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '100px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            placeholder: 'totalY1', // placeholder for filter input
          },
        },
        {
          label: 'totalDiff',
          field: 'totalDiff',
          type: 'number',
          sortable: true,
          //sortFn: this.sortFn,
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '100px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            // placeholder: 'totalDiff', // placeholder for filter input
          },
        },
        {
          label: 'Week-1',
          field: 'yw',
          type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '120px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            placeholder: 'yw', // placeholder for filter input
          },
        },
        {
          label: 'Week-2',
          field: 'yW1',
          type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '120px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            placeholder: 'yW1', // placeholder for filter input
          },
        },
        {
          label: 'Week-3',
          field: 'yW2',
          type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '120px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            placeholder: 'yW2', // placeholder for filter input
          },
        },
        {
          label: 'Week-4',
          field: 'yW3',
          type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '120px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            placeholder: 'yW3', // placeholder for filter input
          },
        },
        {
          label: 'Week-1 LY',
          field: 'lyw',
          type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '120px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            placeholder: 'lyw', // placeholder for filter input
          },
        },
        {
          label: 'Week-2 LY',
          field: 'lyW1',
          type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '120px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            placeholder: 'lyW1', // placeholder for filter input
          },
        },
        {
          label: 'Week-3 LY',
          field: 'lyW2',
          type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '120px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            placeholder: 'lyW2', // placeholder for filter input
          },
        },
        {
          label: 'Week-4 LY',
          field: 'lyW3',
          type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '120px',
          formatFn: this.formatNumbers,
          filterOptions: {
            // enabled: true, // enable filter for this column
            placeholder: 'lyW3', // placeholder for filter input
          },
        },
        {
          label: 'Zipcode',
          field: 'zipcode',
          type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          width: '105px',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: 'Filter >', // placeholder for filter input
            filterFn: this.columnFilterFn, //custom filter function that
            filterDropdownItems: [
              { value: '2000', text: '2000+' },
              { value: '3000', text: '3000+' },
              { value: '4000', text: '4000+' },
              { value: '5000', text: '5000+' },
              { value: '6000', text: '6000+' },
              { value: '7000', text: '7000+' },
              { value: '8000', text: '8000+' },
              { value: '9000', text: '9000+' }
            ],
          },
        },
      ],
      
    }
  },
  mounted() {
    // console.log(this.$route.params);
    // console.log(groupTurnoverByZipcode(TurnOverData));
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Home > Weekly Turnover"}]);
    axios
      .get("/home/weeklyturnover")
      .then((response) => {
        this.data = response.data;
        this.loading = false;
        // console.log(this.groupTurnoverByZipcode(response.data)); 

         this.RegionData = this.groupTurnoverByZipcode(response.data);
      })
      .catch((error) => {
        this.error.response = "Error msg";
        // console.log(error.response)
        this.loading = false;
        throw error;
      });
  },
  methods: {
    formatNumbers: function(value) {
      return Number(value).toLocaleString('nl-NL', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
    },
    columnFilterFn: function(data, columnFilterFn) {
      var x = parseInt(columnFilterFn)
      return data >= x - 0 && data <= x + 999;
    },
    groupTurnoverByZipcode(TurnOverData) {
      var ZipCodeRange = [
        { from: 1000, to: 1999 },
        { from: 2000, to: 2999 },
        { from: 3000, to: 3999 },
        { from: 4000, to: 4999 },
        { from: 5000, to: 5999 },
        { from: 6000, to: 6999 },
        { from: 7000, to: 7999 },
        { from: 8000, to: 8999 },
        { from: 9000, to: 9999 },
        { from: "", to: "" },
        { from: "TOTAL", to: "TOTAL" },
      ];
       var GroupedTurnOverData = [];
       ZipCodeRange.map((zipcode) => {
         var filteredZipcode = TurnOverData.filter(function (zipfilter) { return zipfilter.zipcode >= zipcode.from && zipfilter.zipcode <= zipcode.to; });
         const WeeklyTurnOverData = filteredZipcode.reduce((NewTurnOverData, OldTurnOverData) => {
           for (const [key, value] of Object.entries(OldTurnOverData)) {
              if (
                 typeof value == "number"
               ) {
                 NewTurnOverData[key] ??= 0;
                 NewTurnOverData[key] += value;
               }
           }
           if (zipcode.from == "TOTAL") {
           NewTurnOverData["zipcode"] = "TOTAL";
           } 
           else if(zipcode.from == "") {
           NewTurnOverData["zipcode"] = "EMPTY";
           }
           else {
             NewTurnOverData["zipcode"] = zipcode.from;
           }
           return NewTurnOverData;
         }, {});
         GroupedTurnOverData.push(WeeklyTurnOverData);
       });
      return GroupedTurnOverData;
      // return 0;
    }
  },
  computed: {
    // this.groupTurnoverByZipcode(this.TurnoverData);
  }
};
</script>
